import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'amfe-flexible/index.js'

import { Toast } from 'vant'
Vue.use(Toast)

Vue.config.productionTip = false

// import 'video.js/dist/video-js.css'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
