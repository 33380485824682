import baseUrlObj from "./baseURL"
import axios from 'axios'

export function request(config) {
  const instance = axios.create({
    baseURL: baseUrlObj.baseURL
  })

  instance.interceptors.request.use(config=>{
    if(window.sessionStorage.getItem('token')){
      config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token')
    }
    return config
  },err=>{
    console.log(err)
  })

  instance.interceptors.response.use(res=>{
    return res.data
  },err=>{
    console.log(err)
  })

  return instance(config)
}
