import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = ()=>import("../views/home/Home.vue")
const Detail = ()=>import("../views/detail/Detail.vue")
const News = ()=>import("../views/news/News.vue")

const routes = [
  {
    path: "",
    redirect: "/home",
    meta: {
      showTabBar: true,
      keepAlive: true // 需要缓存
    }
  },
  {
    path: "/home",
    component: Home,
    meta: {
      showTabBar: true,
      keepAlive: true // 需要缓存
    }
  },
  {
    name: 'detail',
    path: "/detail",
    component: Detail,
    meta: {
      showTabBar: false,
      keepAlive: false // 不需要缓存
    }
  },
  {
    name: 'news',
    path: "/news",
    component: News,
    meta: {
      showTabBar: false,
      keepAlive: false // 不需要缓存
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
