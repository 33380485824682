import {request} from './request'

// 获取字典下拉列表 /api/System/ListDict AwardType获奖类型 AwardLevel获奖等级
export function listDict(field){
  return request({
    url: '/api/System/ListDict',
    method: 'get',
    params: {
      field
    }
  })
}




// 下拉列表 店铺产品分类 /api/Shop/SelectShopItemTypeList
export function selectShopItemTypeList(shopNumber,pid){
  return request({
    url: '/api/Shop/SelectShopItemTypeList',
    method: 'get',
    params: {
      shopNumber,
      pid
    }
  })
}

// 获取单个店铺信息 /api​/Shop​/EditShopInfo
export function editShopInfo(shopNumber){
  return request({
    url: '/api/Shop/EditShopInfo',
    method: 'get',
    params: {
      shopNumber
    }
  })
}

// 获取商品所属的商品评论列表 (前台用)（包括评论、图片列表） /api/Shop/GetShopItemReviewList2
export function getShopItemReviewList2(shopNumber,itemNumber,page,size){
  return request({
    url: '/api/Shop/GetShopItemReviewList2',
    method: 'get',
    params: {
      shopNumber,
      itemNumber,
      page,
      size,
    }
  })
}
