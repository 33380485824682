<template>
	<div class="tab-bar">
		<div class="item" v-for="(item,index) in awardTypeList" :key="item.id" :class="activeItem===item.id?'active':''" @click="tabClick(item)">
			<img :src="activeItem===item.id?require('../../../assets/img/tab-item-'+(index+1)+'-s.png'):require('../../../assets/img/tab-item-'+(index+1)+'.png')" alt="">
			<span>{{item.fieldName}}</span>
		</div>
		<!-- <div class="item" :class="activeItem==='right'?'active':''" @click="tabClick('right')">
			<img :src="activeItem==='right'?require('../../../assets/img/tab-item-2-s.png'):require('../../../assets/img/tab-item-2.png')" alt="">
			<span>市售蜂蜜大赛</span>
		</div> -->
	</div>
</template>

<script>
import {listDict} from '../../../network/system'

export default {
  name: "MainTabBar",
  data () {
    return {
      activeItem: 0,
			awardTypeList: []
    };
  },
	mounted() {
		this.listDict('AwardType')
	},
	methods: {
		tabClick(item) {
			this.activeItem = item.id
			this.$emit("mainTabClick",item.fieldValue)
		},
		// 网络请求
		listDict(field) {
      listDict(field).then(res=>{
        if(res.status=200&&res.data){
          if(field==='AwardType'){
            this.awardTypeList = res.data
						this.awardTypeList = this.awardTypeList.reverse()
						this.activeItem = this.awardTypeList[0].id
						this.$emit("mainTabClick",this.awardTypeList[0].fieldValue)
          }
        }
      })
    }
	}
}
</script>

<style lang='less' scoped>
.tab-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 110px;
	background-color: #ffedce;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.item {
		width: 50%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		color: #808080;

		&.active {
			color: #59493f;
			font-weight: 600;
		}
		&.active:first-child {
			background-image: url('../../../assets/img/tab-bg-left.png');
			background-size: 100% 100%;
		}
		&.active:last-child {
			background-image: url('../../../assets/img/tab-bg-right.png');
			background-size: 100% 100%;
		}

		>img {
			width: 34px;
			height: 34px;
			margin-right: 24px;
		}
		>span {
			padding-top: 5px;
		}
	}
}
</style>
