<template>
  <div id="app">
    <!-- <router-view :awardType="awardType" /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :awardType="awardType"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :awardType="awardType"></router-view>
    
    <keep-alive>
      <main-tab-bar v-if="this.$route.meta.showTabBar" @mainTabClick="mainTabClick"></main-tab-bar>
    </keep-alive>
  </div>
</template>

<script>
import MainTabBar from '@/components/content/tabbar/MainTabBar'

export default {
  name: "App",
  data() {
    return {
      awardType: 0,
    }
  },
  components: {
    MainTabBar
  },
  methods: {
    mainTabClick(value) {
      this.awardType=value
    }
  }
}
</script>

<style>
@import url("./assets/css/base.css");

</style>
